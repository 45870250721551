<template>
    <div class="container">
        <md-dialog :md-active.sync="showCamDial">
            <md-dialog-title>Bild aufnehmen</md-dialog-title>
            <md-dialog-content>
                <vue-web-cam
                    id="cam"
                    ref="webcam"
                    :device-id="deviceId"
                    width="100%"
                    @cameras="onCameras"
                    @error="onError"
                    @started="onStarted"
                    @stopped="onStopped"
                    @camera-change="onCameraChange"
                />
                <span id="errormsg" ref="errormsg" style="font-weight: bold; color: red"></span>
            </md-dialog-content>
            <md-dialog-actions>
                <div class="camActions">
                    <md-button :disabled="!deviceId" class="md-raised md-accent" @click="onCapture">
                        <md-icon>camera</md-icon>
                    </md-button>
                    <md-button :disabled="!devices || devices.length <= 1" class="md-raised" @click="onSwitchCamera">
                        <md-icon>camera_front</md-icon>
                        <md-icon>camera_rear</md-icon>
                    </md-button>
                    <md-button :disabled="!deviceId" class="md-raised" @click="onToggleTorch">
                        <md-icon v-if="flash">flash_on</md-icon>
                        <md-icon v-else>flash_off</md-icon>
                    </md-button>
                </div>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showCaptured">
            <md-dialog-title>Aufgenommenes Bild:</md-dialog-title>
            <md-dialog-content>
                <figure class="figure">
                    <img v-if="showCaptured" :src="captured" alt="Das neu aufgenommene Bild." class="img-responsive"/>
                </figure>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" to="" v-on:click="showCaptured = false">Nochmal</md-button>
                <md-button class="md-raised md-primary" to="" v-on:click="saveClose()">Speichern</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<style lang="scss" scoped>
#cam {
    width: fit-content;
    max-width: 100%;
}

div.container {
    width: fit-content;
}

div.camActions {
    width: 100%;
    height: fit-content;
    align-content: center;
    text-align: center;
}

div.spacer {
    margin-top: 10px;
    position: relative;
}

.md-toolbar {
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0;
    margin-bottom: 0;
}
</style>

<script>
export default {
    name: "User_CameraDialog",
    components: {},
    props: {
        active: Boolean,
        img: Object,
    },
    data() {
        return {
            showCaptured: false,
            camera: null,
            deviceId: null,
            devices: [],
            showCamDial: false,
            captured: undefined,
            flash: false,
            stream: undefined,
        };
    },
    computed: {},
    watch: {
        active: function (newVal) {
            if (newVal) {
                this.showCamDial = true;
            }
        },
        camera: function (id) {
            this.deviceId = id;
        },
        devices: function () {
            let first;
            if (!this.devices) {
                return;
            }
            first = this.devices[0];
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
    },
    methods: {
        /**
         * Capture image
         */
        onCapture() {
            this.captured = this.$refs.webcam.capture();
            this.showCaptured = true
        },
        /**
         * To be called when cam was started.
         * @param stream
         */
        onStarted(stream) {
            console.log("On Started Event", stream);
            this.stream = stream;
        },
        /**
         * To be called when cam was stopped.
         * @param stream
         */
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        /**
         * Stop camera
         */
        onStop() {
            this.$refs.webcam.stop();
        },
        /**
         * Start camera
         */
        onStart() {
            this.$refs.webcam.start();
        },
        /**
         * Switch camera
         */
        onSwitchCamera() {
            let idx;
            this.onStop();
            idx = this.devices.findIndex(n => n.deviceId === this.deviceId);
            idx = (idx + 1) % this.devices.length;
            if (this.deviceId === this.devices[idx].deviceId) {
                this.onStart()
            } else {
                this.camera = this.devices[idx].deviceId;
                this.deviceId = this.devices[idx].deviceId
            }
        },
        onToggleTorch() {
            let track = this.stream.getVideoTracks()[0]
            let t = !this.flash

            track.applyConstraints({
                advanced: [{torch: t}]
            })

            this.flash = t
        },
        /**
         * To be called on error.
         * @param error
         */
        onError(error) {
            console.log("On Error Event", error);
            this.$refs.errormsg.innerHTML = "<br>Kamera nicht gefunden!"
        },
        /**
         * To be called on cameras found.
         * @param cameras
         */
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
            this.onStart()
        },
        /**
         * To be called on camera changed
         * @param deviceId
         */
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },
        /**
         * Save image and close dialogs
         */
        saveClose() {
            this.onStop();
            this.img.data = this.captured;
            this.showCaptured = false;
            this.showCamDial = false;
            this.$emit("onNewImage")
        }
    }
};
</script>

<!-- TODO: Prinzip aus All_LoginDialog übernehmen -->
